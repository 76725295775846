<template>
  <div class="egg">
    <navMenu/>
    <div class='title'>{{ $t("page.egg.충전번호") }}</div>
    <eggInput/>
    <div class="padding"></div>
    <displayCost/>
    <prepayButton/>
  </div>
</template>

<script>
// @ is an alias to /src
import navMenu from '@/components/navMenu.vue'
import eggInput from '@/components/egg_input.vue'
import displayCost from '@/components/displayCost.vue'
import prepayButton from '@/components/prepayButton.vue'

export default {
  name: 'Home',
  components: {
    navMenu,
    eggInput,
    displayCost,
    prepayButton
  },
  mounted(){
    this.$store.commit('setMonthly_cost', null)
    this.$store.commit('setMonthly_name', '')
    this.$store.commit('setPhoneNum', '')

    this.$store.commit('setPrepay_cost', '')
    
    this.$store.commit('setEgg_cost', 66000)
  }
}
</script>

<style scoped>
.title {
  text-align: left;
  padding-left: 1rem;
  margin-top: 1rem;
}
.padding {
  padding: 2rem 0 2rem 0;
}
</style>