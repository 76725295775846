<template>
  <div class="monthly">
    <navMenu/>
    <div class='title'>{{ $t('page.일반선불.충전번호') }}</div>
    <phoneInput/>
    <payPlanSelect v-bind:selectList='telecom' v-bind:title="$t('page.월정액.충전가능통신사')"/>
    
    <img src="@/assets/telecoms.jpg" class="img-fluid">
    <div class='title'>{{ $t('page.충전금액') }}</div>
    <div class="present">{{localeCost}}</div>
    <priceDropdown v-bind:plan="plan"/>
    <div class="notice" v-if="planName == '통신사 모름'">
      <span class="n_title">[วิธีการเติมเงินแพ็คเกจรายเดือน]</span><br>
      <span>▶️ ไม่ทราบชื่อเครือข่าย ทราบแค่ราคาแพ็คเกจ ก็เติมได้ค่ะ</span><br>
      <span>▶️ หากเติมล้มเหลว กรุณาเลือกชื่อเครือข่าย/ราคาแพ็คเกจที่สมัครไว้ให้ถูกต้อง</span><br>
      <span>เงินที่ถูกหักไปจะคืนไว้ในแอพที่ Cash</span><br>
      <span>▶️ หลังจากเติมแล้ว สามารถใช้งานได้ 30 วัน</span><br>
      <span>▶️ ค่าบริการเสริม(โทรต่างประเทศ/วิดีโอคอล)อาจหมดก่อน การใช้งาน 30 วัน</span><br>
      <span>▶️ รายละเอียดเพิ่มเติม สอบถามเครือข่ายโดยตรงที่ 114</span>
    </div>
    <displayCost/>
    
    <prepayButton/>
  </div>
</template>

<script>
// @ is an alias to /src
import navMenu from '@/components/navMenu.vue'
import phoneInput from '@/components/phone_input.vue'
import payPlanSelect from '@/components/payPlanSelect.vue'
import priceDropdown from '@/components/priceDropdown.vue'
import displayCost from '@/components/displayCost.vue'
import prepayButton from '@/components/prepayButton.vue'

export default {
  name: 'monthly',
  components: {
    navMenu,
    phoneInput,
    payPlanSelect,
    priceDropdown,
    displayCost,
    prepayButton
  },
  data: function(){
    return {
      telecom: [
        '통신사 모름',
        'Smartel',
        'Eyagi',
        'U+mobile',
        'Myworld',
        'Eyes',
        'FreeT',
        'Mobing',
        'Snowman',
        'Code Mobile(EG)',
        '7mobile',
        'Joytel',
        'YEOYOU',
        'OneTelecom',
        'WHOM',
        'Dream Mobile',
        'KTM-mobile',
        'Well',
        'Niztelecom',
        'Valuecomm(LGT)',
        'Voice(Special)'
      ],
      selectedTelecom: '',
      plan: this.$store.state.monthly_plan
    }
  },
  computed:{
    localeCost: function(){
      let cost = this.$store.state.monthly_cost

      if(cost == null){
        return this.$i18n.t("page.월정액.요금제를선택해주세요")//'아래 요금제 중 선택해주세요.'
      }
      console.log(cost)
      console.log(typeof cost)
      return '￦'+cost.toLocaleString()
    },
    planName: function(){
      console.log(this.$store.state.prepay_name)
      return this.$store.state.prepay_name.replace('_',' ')
    }
  },
  beforeMount(){
    this.$store.commit('setPrepay_type', 'monthly')
    this.$store.commit('setPrepay_cost', this.telecom[0])
  },
  mounted(){
    this.$store.commit('setEggNum', '')
    this.$store.commit('setEgg_cost', null)
  }
}
</script>

<style scoped>
.title {
  text-align: left;
  padding-left: 1rem;
  margin-top: 1rem;
}
.img-fluid {
  padding: 0 1rem;
}
.present {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: .375rem .75rem;
  margin: 0.5rem 1rem;
  min-height: 2.2rem;
}
.notice {
  margin-top: 0.4rem;
  margin: 1rem;
  border: 1px black solid;
  padding: 1rem;
  text-align: left;
  font-size: 0.75rem;
}
.n_title {
  font-size: 0.8rem;
  font-weight: bold;
}
</style>
