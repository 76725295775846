<template>
  <div class="egg_input">
    <input type="number" class="form-control" id="phone" v-bind:placeholder="$t('egg')">
    <div class="invalid-feedback">
        โปรดกรอกเฉพาะตัวเลขเท่านั้น
      </div>
      <!--잘못된 입력입니다.-->
  </div>
</template>

<script>
export default {
  name: 'phoneInput',
  props: {
  },
  mounted(){
    let egg_input = document.querySelector('input[type=number]')
    let instance = this

    egg_input.addEventListener('change', function(event){
      let re = new RegExp("\\D")
      if(event.target.value.match(re)){
        egg_input.classList.add('is-invalid')
        return
      }
      /*if(event.target.value.length != 11){
        egg_input.classList.add('is-invalid')
        return
      }*/
      egg_input.classList.remove('is-invalid')
      instance.$store.commit('setEggNum', event.target.value)
      return
    })

    egg_input.addEventListener('focus', function(event){
      event.target.value = ''
      instance.$store.commit('setEggNum', event.target.value)
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.phone_input {
  margin: 0 1rem;
}
</style>
