<template>
  <div class="container">
    <div class='storage' hidden>{{items}}</div>
    <div class="btn-group plan1">
      <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
        {{plan[items][0][0]}}
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="button" v-for="(item, index) in (plan[items][0]).slice(1)" :key="item[0]" :value="items+'_0_'+index">
          <div class="dropdown-divider" v-if="index != 0"></div>
          <div v-if="typeof item != 'number'">
            <div class="plan_str" v-for="(v,i) in item" :key="v" :value="i">{{trans_i18n(v)}}</div>
          </div>
          <div v-else>
            {{'￦' + item.toLocaleString()}}
          </div>
        </button>
      </div>
    </div>
    <div v-if="plan[items].length > 1" class="btn-group plan2">
      <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
        {{plan[items][1][0]}}
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="button" v-for="(item, index) in (plan[items][1]).slice(1)" :key="item[0]" :value="items+'_1_'+index">
          <div class="dropdown-divider" v-if="index != 0"></div>
          <div v-if="typeof item != 'number'">
            <div class="plan_str" v-for="(v,i) in item" :key="v" :value="i">{{trans_i18n(v)}}</div>
          </div>
          <div v-else>
            {{'￦' + item.toLocaleString()}}
          </div>
        </button>
      </div>
    </div>
    <div v-if="plan[items].length > 2" class="btn-group plan3">
      <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
        {{plan[items][2][0]}}
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="button" v-for="(item, index) in (plan[items][2]).slice(1)" :key="item[0]" :value="items+'_2_'+index">
          <div class="dropdown-divider" v-if="index != 0"></div>
          <div v-if="typeof item != 'number'">
            <div class="plan_str" v-for="(v,i) in item" :key="v" :value="i">{{trans_i18n(v)}}</div>
          </div>
          <div v-else>
            {{'￦' + item.toLocaleString()}}
          </div>
        </button>
      </div>
    </div>
    <div v-if="plan[items].length > 3" class="btn-group plan4">
      <button type="button" class="btn btn-secondary dropdown-toggle" data-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false">
        {{plan[items][3][0]}}
      </button>
      <div class="dropdown-menu">
        <button class="dropdown-item" type="button" v-for="(item, index) in (plan[items][3]).slice(1)" :key="item[0]" :value="items+'_3_'+index">
          <div class="dropdown-divider" v-if="index != 0"></div>
          <div v-if="typeof item != 'number'">
            <div class="plan_str" v-for="(v,i) in item" :key="v" :value="i">{{trans_i18n(v)}}</div>
          </div>
          <div v-else>
            {{'￦' + item.toLocaleString()}}
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'priceDropDown',
  props: [
    'plan'
  ],
  data: function(){
    return {
    }
  },
  computed: {
    items: function(){
      if(this.$store.state.prepay_name == ''){
        return ''
      }
      return this.$store.state.prepay_name.replace('_',' ')
    }
  },
  mounted(){
    this.selectionEvent()
  },
  updated(){
    let trans = document.querySelectorAll('.plan_str[value="1"]')
    for (const row of trans) {
      row.innerText = parseInt(row.innerText).toLocaleString()
    }
    this.$store.commit('setMonthly_cost', null)
    this.selectionEvent()
  },
  methods: {
    selectionEvent(){
      let buttons = document.querySelectorAll('.dropdown-item')
      let instance = this
      for (const row of buttons) {
        row.addEventListener('click', function(event){
          let telecom, range, target
          [telecom, range, target] = event.target.closest('.dropdown-item').value.split('_')
          
          let price = instance.plan[telecom][parseInt(range)][parseInt(target)+1]
          instance.$store.commit('setMonthly_name', '')

          if(typeof instance.plan[telecom][parseInt(range)][parseInt(target)+1] != 'number'){
            let name = instance.plan[telecom][parseInt(range)][parseInt(target)+1][0]
            price = instance.plan[telecom][parseInt(range)][parseInt(target)+1][1]
            instance.$store.commit('setMonthly_name', name)
          }
          instance.$store.commit('setMonthly_cost', price)
        })
      }
    },
    trans_i18n(str){
      if(String(str).indexOf('국제전화') != -1){
        return ''
      }
      return String(str).replace(
        /음성.{1}문자/g, this.$i18n.t("plan.음성/문자")).replace(
          "음성 + 문자", this.$i18n.t("plan.음성/문자")).replace(
            "가입하신 요금제에 상관없이 충전금액을 자유롭게 선택가능", this.$i18n.t("plan.가입하신~")).replace(
              "충전가능시간: 11시 ~ 19시 (주말포함), 이후시간요청건은 익일에 처리됩니다.", this.$i18n.t("plan.충전가능시간"))/*.replace(
            /"일 20분 제공", this.$i18n.t("plan.일20분제공"))*/.replace(
              /무제한/g, this.$i18n.t("plan.무제한")).replace(
                /데이터/g, this.$i18n.t("plan.데이터")).replace(
                  '음성', this.$i18n.t("plan.음성")).replace(
                    '문자', this.$i18n.t("plan.문자")).replace(
                      '속도제한', '')/*.replace(
                        '국제전화', this.$i18n.t("plan.국제전화"))*/.replace(
                          '분', this.$i18n.t("plan.분")).replace(
                            '매일', this.$i18n.t("plan.매일")).replace(
                              '시', this.$i18n.t("plan.시")).replace(
                                '건', this.$i18n.t("plan.건"))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.btn-group > button {
  font-size: 0.8rem;
}
.dropdown-menu {
  min-width: 5rem;
}
.dropdown-item {
  padding: 0.25rem 0.8rem;
}
.plan_str { 
  font-size: 14px;
  overflow: auto;
  white-space: break-spaces;
  max-width: 17rem;
}
div[value="1"]{
  text-align: right;
  color:royalblue
}
div[value="1"]::before {
  content: '￦';
}
</style>
