<template>
  <div class="container-fluid">
        <div class="payment_head">{{ $t('payment.head') }}</div>
        <div class="payment_info">
          <div class="payment_info_row"><div>{{ $t("payment.bank_name") }}</div><div>HANABANK(하나은행)</div></div>
          <div class="payment_info_row"><div>{{ $t('payment.bank_account') }}</div><div>139-910420-87107</div></div>
          <div class="payment_info_row"><div>{{ $t('payment.ko_account_name') }}</div><div>김준형</div></div>
          <div class="payment_info_row payment_row"><div>{{ $t('payment.cost_amount') }}</div><div>{{decoPrice()}}</div></div>

          <div class="payment_info_row_th"><div>ธนาคาร</div><div>กรุงเทพ</div></div>
          <!--<div class="payment_info_row_th"><div>หมายเลขบัญชี</div><div>995-008324-7</div></div>
          <div class="payment_info_row_th"><div>ชื่อบัญชีไทย</div><div>น.ส.ช่อนกลิ่น ขันธวี</div></div>-->
          <div class="payment_info_row_th"><div>หมายเลขบัญชี</div><div>970-0-305353</div></div>
          <div class="payment_info_row_th"><div>ชื่อบัญชีไทย</div><div>MR JUNHYEONG KIM</div></div>
          <div class="payment_info_row_th payment_row"><div>ยอดที่ชำระ</div><div>{{decoPriceth()}}</div></div>
        </div>
        <div class="payment_submit_box">
            <div class="payment_notice"><div>{{ $t('payment.notice') }}</div></div>
            <div class="payment_submit" @click="endProcess"><div>{{ $t('payment.submit') }}</div></div>
        </div>
        <div class="payment_QnA"><div>{{ $t('payment.QnA') }}</div></div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'payment',
  components: {
  },
  data: function () {
    return {
      clicked: false
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    decoPrice(){
      let prepay = this.$store.state.prepay_cost
      let monthly = this.$store.state.monthly_cost
      let egg = this.$store.state.egg_cost

      let cost = parseInt(prepay == null ? (monthly == null ? egg : monthly) : prepay) + this.$store.state.service_fee
      return '￦'+parseInt(cost).toLocaleString()
    },
    decoPriceth(){
      let prepay = this.$store.state.prepay_cost
      let monthly = this.$store.state.monthly_cost
      let egg = this.$store.state.egg_cost

      let cost = parseInt(prepay == null ? (monthly == null ? egg : monthly) : prepay) + this.$store.state.service_fee
      let thCost =  Math.ceil(parseInt(cost)/33.3333)
      return parseInt(thCost).toLocaleString() + ' บาท'
    },
    endProcess() {
      if(this.clicked == true){
        return
      }
      this.clicked = true

      document.querySelector('.payment_submit > div').classList.add('loader')

      let infoDict = {
        'phone': this.$store.state.phone, //전화번호
        'prepay_type': this.$store.state.prepay_type, //voice or data
        'prepay_name': this.$store.state.prepay_name, //voice 요금 or data plan 이름
        'prepay_cost': this.$store.state.prepay_cost, //선불 금액
        'monthly_name': this.$store.state.monthly_name, //월정액 회사
        'monthly_cost': this.$store.state.monthly_cost, //월정액 금액
        'service_fee': this.$store.state.service_fee, //서비스 요금
        'egg' : this.$store.state.egg, //egg 번호
        'egg_cost' : this.$store.state.egg_cost, //egg 요금
      }
      let instance = this

      const plan = infoDict.monthly_name == '' ? (infoDict.prepay_type == '' ? 'egg' : infoDict.prepay_type + ' ' + infoDict.prepay_name)  : infoDict.monthly_name
      const planCost = infoDict.prepay_cost == null ? (infoDict.monthly_cost == null ? infoDict.egg_cost : infoDict.monthly_cost) : infoDict.prepay_cost
      const serviceFee = infoDict.service_fee
      const totalCost = parseInt(planCost) + parseInt(serviceFee)
      infoDict['total_cost'] = totalCost

      const msg = `전화번호 ${infoDict.phone}
요금제 ${plan}
총비용 ${totalCost}`

      var urer_info = null
      instance.$liff.get_liff().getProfile().then(
        profile => {
          urer_info = profile

          infoDict['user_info'] = urer_info
      
          axios.post('/payment', infoDict).then((x)=>{

            /*const msg = `주문번호 ${x.data.id}
전화번호 ${infoDict.phone}
요금제 ${plan}
총비용 ${totalCost}`*/
            let msg = ''
            if(infoDict.phone != ''){
              msg = `หมายเลขคำสั่งซื้อ ${x.data.id}
หมายเลขโทรศัพท์ ${infoDict.phone}
เครือข่ายโทรศัพท์มือถือ ${plan}
ยอดที่ชำระ ${totalCost}`
            }
            else{
              msg = `หมายเลขคำสั่งซื้อ ${x.data.id}
egg number ${infoDict.egg}
ยอดที่ชำระ ${totalCost}`
            }

            if(x.data.result == 'ok'){
              instance.$liff.get_liff().sendMessages([{
                type: 'text',
                text: msg
              }]).then(()=>{
                instance.$liff.get_liff().closeWindow()
              }).catch((err)=>{
                alert(err)
              })
            }
          })
        }
      ).catch(
        (err) => {
          console.log('error', err);
        }
      )

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img-wrap {
  display: flex;
  align-items: center;
}
.head {
  margin-top: 1rem;
}
.payment_head {
    /*font-size: 15.4px;*/
    font-size: 1.2rem;
    font-weight: 600;
}
.payment_info {
    border: royalblue 1px solid;
}
.payment_info_row, .payment_info_row_th {
    display: grid;
    grid-template-columns: 30% auto;
}
.payment_info_row > div, .payment_info_row_th > div {
    text-align: left;
    padding: 5px 0 5px 10px;
    font-size: 13px;

}
.payment_info_row > div:nth-child(2n-1) {
    background-color: royalblue;
    color: white;
}
.payment_info_row_th > div:nth-child(2n-1) {
    background-color: #43ada8;
    color: white;
}
.payment_row {
    color: rgb(205, 100, 100);
}
.payment_submit_box, .payment_QnA {
    background-color: #e6e6e6;
    padding: 10px;   
}
.payment_notice {
    text-align: left;
    /*font-size: 11px;*/
    font-size: 1.0rem;
    padding: 0px 0px 10px 0px;
}
.payment_notice > div {
    text-align: left;
}
.payment_QnA, .payment_submit_box{
    margin: 10px 0 10px 0;
}
.payment_submit {
    background-color: rosybrown;
    color: white;
    padding: 10px 0 10px 0;
    font-size: 1.2rem;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #ffffff;
  font-size: 10px;
  margin: -1.5rem auto 2rem auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}


</style>